import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, {useState, lazy, Suspense, useContext, useEffect} from 'react';
import {Switch, Route, Redirect, useRouteMatch} from 'react-router-dom';
import AppBarContext from '../components/AppBarContext';
import AuthContext from '../components/AuthContext';
import DrawerContext from '../components/DrawerContext';
import WebAppBar from '../components/WebAppBar';
import {OPERATOR_PATH, USERS_PATH, USER_PATH, OPERATORS_PATH,} from '../Constants';
import PrivilegeRoute from '../fhg/components/security/PrivilegeRoute';
import FHGTypography from '../fhg/components/Typography';

const Users = lazy(() => import('./admin/Users'));
const Operators = lazy(() => import('./superAdmin/Operators'));

const useStyles = makeStyles(theme => ({
   root: {
      overflow: 'hidden',
      height: '100vh',
      fontFamily: theme.typography.fontFamily,
   },
   contentStyle: {
      width: '100%',
      height: '100%',
      flex: '1 1',
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
      padding: theme.spacing(1),
   },
   progressStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
   },
}), {name: 'mainStyles'});

/**
 * Main component accessible only if the user has been authenticated. Contains the routing for the application. Is the
 * Provider for the DrawerContext and the AppBarContext.
 *
 * Reviewed: 3/26/20
 */
export default function Main() {
   const classes = useStyles();
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const {isSuperAdmin, isOperatorAdmin, operatorId} = useContext(AuthContext);
   const [titleKey, setTitleKey] = useState('application.title');
   const [subtitleKey, setSubtitleKey] = useState();
   const [subtitleValues, setSubtitleValues] = useState({operator: ''});

   const userPath = isOperatorAdmin ? USER_PATH.replace(':operatorId', operatorId) : USER_PATH;
   const userMatch = useRouteMatch({
      path: userPath,
      strict: true,
      sensitive: true
   });

   /**
    * Set the app bar titles based on the URL path.
    */
   useEffect(() => {
      if (userMatch) {
         setSubtitleKey('operator.appbar.title');
      } else if (isSuperAdmin) {
         setSubtitleKey('super.title');
      }
   }, [userMatch, isSuperAdmin]);

   /**
    * When the user closes the sidebar, mark the sidebar closed.
    */
   const onDrawerClose = () => {
      setIsDrawerOpen(false);
   };

   /**
    * When the user opens the sidebar, mark the sidebar open.
    */
   const onDrawerOpen = () => {
      setIsDrawerOpen(true);
   };

   // Set the default path based on the type of user. Super admin default to the operators path and admin to the user
   // path for their operator.
   const defaultPath = isSuperAdmin ? OPERATORS_PATH :
      isOperatorAdmin ? USERS_PATH.replace(':operatorId', operatorId) : undefined;

   return (
      <AppBarContext.Provider
         value={{titleKey, setTitleKey, subtitleKey, setSubtitleKey, subtitleValues, setSubtitleValues}}>
         <DrawerContext.Provider value={{isDrawerOpen, onDrawerClose, onDrawerOpen}}>
            <Grid container direction={'column'} className={classes.root}>
               <CssBaseline/>
               <WebAppBar/>
               <main className={classes.contentStyle}>
                  <Suspense fallback={<CircularProgress className={classes.progressStyle}/>}>
                     <Switch>

                        <PrivilegeRoute hasPrivilege={isSuperAdmin} exact path={OPERATOR_PATH} component={Operators}/>
                        <PrivilegeRoute hasPrivilege={(isOperatorAdmin && userMatch) || isSuperAdmin} path={USER_PATH}
                                        component={Users}/>
                        {defaultPath ? (
                           <Route path='/'>
                              <Redirect to={defaultPath}/>
                           </Route>
                        ) : (
                           <FHGTypography variant={'h6'} style={{margin: 32}} id={'noPermission.message'}/>
                        )}
                     </Switch>
                  </Suspense>
               </main>
            </Grid>
         </DrawerContext.Provider>
      </AppBarContext.Provider>
   );
}

