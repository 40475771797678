import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {isEqual} from 'lodash';
import PropTypes from 'prop-types';
import React, {Fragment, useEffect, useState} from 'react';
import DisplayError from './DisplayError';

const useStyles = makeStyles({
   progressStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
   },
}, {name: 'dataLoadingAndErrorsStyles'});

let current;

/**
 * Component to show the error messages and loading spinner.
 *
 * Note:
 *    Message is the default property in values.
 *
 * Reviewed: 3/26/20
 */
export default function DataLoadingAndErrors({isLoading, enableRefresh, onClose, errorId, error, showOnLoad, values, children}) {
   const classes = useStyles();
   const [open, setOpen] = useState(true);

   /**
    * Handles updating the open property when a new error is displayed.
    */
   useEffect(() => {
      const newError = {errorId, error, values};
      if (!isEqual(current, newError)) {
         current = newError;
         setOpen(true);
      } else {
         setOpen(false);
      }
      return () => {
         current = undefined;
      }
   }, [error, errorId, values]);

   /**
    * Close the snackbar and remove current if it is the error showing.
    */
   const handleClose = () => {
      current = undefined;
      setOpen(false);
      onClose && onClose();
   };

   return (
      <Fragment>
         {open && (
            <DisplayError error={error} onClose={handleClose} errorId={errorId} values={values || error}
                          enableRefresh={enableRefresh}/>
         )}
         {isLoading && <CircularProgress className={classes.progressStyle}/>}
         {(!isLoading || showOnLoad) && children}
      </Fragment>
   );
}

DataLoadingAndErrors.propTypes = {
   isLoading: PropTypes.bool,                // Indicates if the data is still loading.
   enableRefresh: PropTypes.bool,            // Indicates if the Refresh action should be shown / enabled.
   onClose: PropTypes.func,                  // Callback when the snackbar is closed.
   errorId: PropTypes.string,                // Intl ID for the error message.
   error: PropTypes.any,                     // The error to show.
   showOnLoad: PropTypes.bool,               // Indicates if the children should be shown while loading.
   values: PropTypes.object,                 // Value object for the error message.
};

DataLoadingAndErrors.defaultProps = {
   enableRefresh: true,
   errorId: 'fetch.error',
   showOnLoad: false,
};

