import {useQuery} from '@apollo/react-hooks';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Switch from '@material-ui/core/Switch';
import gql from 'graphql-tag';
import {toNumber} from 'lodash';
import get from 'lodash/get';
import React, {useState, useEffect} from 'react';
import DocumentTitle from 'react-document-title';
import {useIntl} from 'react-intl';
import {useParams, useLocation, useHistory, Prompt} from 'react-router-dom';
import FormCard from '../../components/FormCard';
import FHGTextField from '../../components/TextField';
import {USER_PATH, USERS_PATH} from '../../Constants';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import DataLoadingAndErrors from '../../fhg/components/DataLoadingAndErrors';
import Grid from '../../fhg/components/Grid';
import FHGTypography from '../../fhg/components/Typography';
import {formatMessage} from '../../fhg/utils/Utils';

const useUserStyles = makeStyles(theme => ({
   contentStyle: {
      overflow: 'auto',
      height: '100%',
      flex: '1 1',
   },
   paperStyle: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      maxHeight: `calc(100% - ${theme.spacing(4)}px)`,
      [theme.breakpoints.down('sm')]: {
         margin: theme.spacing(1),
         maxHeight: `calc(100% - ${theme.spacing(2)}px)`,
      },
      maxWidth: 720,
      display: 'flex',
      overflow: 'hidden',
   },
   titleStyle: {
      marginBottom: theme.spacing(2),
   },
   titleStyleInner: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
   },
   noDataTitleStyle: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
   },
}), {name: 'userStyles'});

// The properties of User needed for the user queries. Always use the same properties to aid caching.
export const USER_FRAGMENT = gql`
   fragment userInfo on User {
      id
      name
      email
      operatorId
      isOperatorAdmin
      lastTestDateTime
      lastLoginDateTime
      isActive
   }
`;

// The users for the given property ID and the operator name.
export const USERS_QUERY = gql`
   query getUsers($operatorId: Int!) {
      users:user_AllWhere(userSearch: {operatorId: [$operatorId]}) {
         ...userInfo
      }
      operator:operator_ById(operatorId: $operatorId) {
         id
         name
         privacyPolicy
      }
   }
   ${USER_FRAGMENT}
`;

// The user with the given user ID and property ID.
export const USER_BY_ID_QUERY = gql`
   query getUserById($userId: Int!, $operatorId: Int!)
   {
      user:user_AllWhere(userSearch: {id: [$userId], operatorId: [$operatorId]}) {
         ...userInfo
      }
   }
   ${USER_FRAGMENT}
`;

// Create a user with the given properties.
const USER_CREATE = gql`
   mutation UserCreate($name: String, $email: String, $lastLoginDateTime: String, $operatorId: Int, $isOperatorAdmin: Boolean!, $isActive: Boolean!) {
      user: user_Create(user: {name: $name, email: $email, lastLoginDateTime: $lastLoginDateTime, operatorId: $operatorId, isOperatorAdmin: $isOperatorAdmin, isActive: $isActive}) {
         ...userInfo
      }
   }
   ${USER_FRAGMENT}
`;

// Update a user with the given properties.
const USER_UPDATE = gql`
   mutation UserUpdate($userId: Int!, $name: String, $email: String, $lastLoginDateTime: String, $isOperatorAdmin: Boolean, $isActive: Boolean) {
      user: user_Update(userId: $userId, user: {name: $name, email: $email, lastLoginDateTime: $lastLoginDateTime, isOperatorAdmin: $isOperatorAdmin, isActive: $isActive}) {
         ...userInfo
      }
   }
   ${USER_FRAGMENT}
`;

/**
 * The User component to create and edit a user.
 *
 * Reviewed: 3/26/20
 */
export default function User({initialUser}) {
   const {operatorId: operatorIdAsString, userId: userIdAsString} = useParams();
   const operatorId = toNumber(operatorIdAsString);
   const userId = toNumber(userIdAsString);
   const location = useLocation();
   const history = useHistory();
   const intl = useIntl();
   const classes = useUserStyles();
   const {isCreate, isMultiple} = get(location, 'state', {isCreate: false, isMultiple: false});
   const [initialValues, setInitialValues] = useState();
   const {data, loading, error} = useQuery(USER_BY_ID_QUERY, {variables: {userId, operatorId}, skip: !userId || !!initialUser || isCreate || isMultiple});
   const [userCreate, {loading: createLoading, error: createError}] = useMutationFHG(USER_CREATE, undefined,
      {query: USERS_QUERY, variables: {operatorId}});
   const [userUpdate, {loading: updateLoading, error: updateError}] = useMutationFHG(USER_UPDATE, {skip: !userId});
   const [isActive, setActive] = useState(true);
   const [isOperatorAdmin, setOperatorAdmin] = useState(false);
   const [isChanged, setIsChanged] = useState(false);
   const [isSaving, setIsSaving] = useState(false);
   const [editValues, setEditValues] = useState({});

   /**
    * Toggle the active property. Changes are immediately saved.
    * @param event The checkbox event.
    */
   const toggleActiveChecked = (event) => {
      setActive(event.target.checked);
      userUpdate({variables: {userId, isActive: event.target.checked}});
   };

   /**
    * Toggle the operator admin property.
    */
   const toggleOperatorAdminChecked = () => {
      setIsChanged(true);
      setOperatorAdmin(prev => !prev);
   };

   /**
    * Set the default values and active from the existing user.
    */
   useEffect(() => {
      if (!!data) {
         setInitialValues(get(data, 'user[0]') || {});
         setActive(get(data, 'user[0].isActive'));
         setOperatorAdmin(get(data, 'user[0].isOperatorAdmin'));
      }
   }, [data]);

   /**
    * Set the default values and active from the existing user.
    */
   useEffect(() => {
      if (!!initialUser) {
         setInitialValues(initialUser);
         setActive(get(initialUser, 'isActive'));
         setOperatorAdmin(get(initialUser, 'isOperatorAdmin'));
      } else {
         setInitialValues(undefined);
         setActive(true);
         setOperatorAdmin(false);
      }
   }, [initialUser]);

   /**
    * Handle onChange events for the inputs.
    *
    * NOTE:
    * Input components MUST have their name set to be set in the editValues.
    *
    * @param event The event that changed the input.
    */
   const handleChange = event => {
      let value;

      switch (event.target.type) {
         case 'number':
            value = event.target.valueAsNumber;
            break;
         case 'checkbox':
            value = event.target.checked;
            break;
         default:
            value = event.target.value;
            break;
      }
      setEditValues({...editValues, [event.target.name]: value});
      setIsChanged(true);
   };

   /**
    * Handle onSubmit for the form. Mutates the database object with the changes.
    */
   const handleSubmit = async () => {
      let result;

      if (isChanged) {
         try {
            setIsSaving(true);
            let mutate = isCreate ? userCreate : userUpdate;

            // Handles saving the list of emails as users.
            if (isMultiple) {
               const emailList = editValues.email.split(',');
               const allUserCreatePromises = [];

               // For each email in the list.
               for (const email of emailList) {
                  const userEmail = email && email.trim();

                  if (userEmail) {
                     allUserCreatePromises.push(mutate({variables: {email, operatorId, isOperatorAdmin, isActive}}));
                  }
               }
               const multipleResults = await Promise.all(allUserCreatePromises);
               if (multipleResults && multipleResults.length > 0) {
                  result = multipleResults[0];
               }
            } else {
               result = await mutate({variables: {userId, ...editValues, operatorId, isOperatorAdmin, isActive}});
            }
            setIsChanged(false);
            setInitialValues(get(result, 'data.user') || {});
            setEditValues({});
            setIsSaving(false);

            if (isCreate) {
               const path = USER_PATH.replace(':operatorId', operatorIdAsString)
                  .replace(':userId?', result.data.user.id);
               history.replace(path);
            }
         } catch (error) {
            setIsSaving(false);
         }
      }
   };

   /**
    * Cancel the changes to the user.
    */
   const handleCancel = () => {
      setIsChanged(false);
      setEditValues({});
      setInitialValues(undefined);
      // Wait until isChanged takes affect to avoid Prompt.
      setTimeout(() => {
         history.replace(USERS_PATH.replace(':operatorId', operatorIdAsString), {isCreate: false, isMultiple: false});
      }, 0);
   };

   if (!isCreate && userId && !initialValues) {
      return <DataLoadingAndErrors error={error} isLoading={loading}/>
   }
   // If a new user isn't being created and not existing user designated, display the message.
   if (!isCreate && !userId) {
      return (
         <FHGTypography className={classes.noDataTitleStyle} variant={'h5'} noWrap id={'user.noUser.title'}/>
      );
   }
   // If the data comes back but doesn't match a user, show the message.
   if (data && data.user.length <= 0) {
      return (
         <FHGTypography className={classes.noDataTitleStyle} variant={'h5'} noWrap id={'user.invalidUser.title'}/>
      )
   }
   const title = get(initialValues, 'name');
   const titleKey = isMultiple ? 'user.multipleUsers.title' : isCreate ? 'user.newUser.title' : 'user.user.title';

   return (
      <DocumentTitle title={title || ''}>
         <Grid item className={classes.contentStyle}>
            <Prompt when={isChanged} message={() => formatMessage(intl, 'leavePage', 'Discard changes?')}/>
            <DataLoadingAndErrors error={error || createError || updateError}
                                  isLoading={loading || createLoading || updateLoading}/>
            <Paper square className={classes.paperStyle}>
               <FormCard onSubmit={handleSubmit} onCancel={handleCancel} disabled={isSaving}>
                  <Grid item container className={classes.titleStyle} fullWidth={true} justify={'space-between'}
                        resizable={false}>
                     <Grid item flex={'2000 2000'} style={{minWidth: 'min-content', maxWidth: 'fit-content'}}>
                        <FHGTypography variant={'h5'} className={classes.titleStyleInner} id={!title ? titleKey : undefined}>
                           {title}
                        </FHGTypography>
                        {isMultiple && (
                           <FHGTypography variant='subtitle1' id={'user.multipleUsers.subtitle'}/>
                        )}
                     </Grid>
                     <Grid item resizable={false} style={{minWidth: 'fit-content'}} overflow={'visible'}>
                        <FormControlLabel
                           control={<Switch checked={isActive} onChange={toggleActiveChecked} color={'primary'}/>}
                           label={<FHGTypography noWrap id={'user.active.label'}/>}
                           style={{marginRight: 0}}
                        />
                     </Grid>
                  </Grid>
                  <Grid container direction={'row'} spacing={2} resizable overflow={'auto'}>
                     {!isMultiple && (
                        <Grid item xs={12}>
                           <FHGTextField
                              key={'Name Field' + (initialValues && initialValues.name)}
                              name={'name'}
                              autoFocus
                              label={<FHGTypography variant='inherit' id={'user.name.label'}/>}
                              defaultValue={initialValues && initialValues.name}
                              value={editValues.name}
                              onChange={handleChange}
                              disabled={isSaving}
                              fullWidth
                           />
                        </Grid>
                     )}
                     <Grid item xs={12}>
                        <FHGTextField
                           key={'Email Field' + (initialValues && initialValues.email)}
                           name={'email'}
                           autoFocus={isMultiple}
                           type={isMultiple ? 'text' : 'email'}
                           label={<FHGTypography variant='inherit'
                                                 id={isMultiple ? 'user.emailList.label' : 'user.email.label'}/>}
                           defaultValue={initialValues && initialValues.email}
                           value={editValues.email}
                           multiline={isMultiple}
                           rows={isMultiple ? 4 : undefined}
                           rowsMax={isMultiple ? 8 : undefined}
                           onChange={handleChange}
                           required
                           disabled={isSaving}
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <FormControlLabel
                           style={{paddingLeft: 11}}
                           control={(
                              <Checkbox
                                 name={'isOperatorAdmin'}
                                 checked={isOperatorAdmin}
                                 onChange={toggleOperatorAdminChecked}
                                 disabled={isSaving}
                                 value={'isOperatorAdmin'}
                              />
                           )}
                           label={<FHGTypography id={'user.operatorAdmin.label'}/>}
                        />
                     </Grid>
                  </Grid>
               </FormCard>
            </Paper>
         </Grid>
      </DocumentTitle>
   );
}
