import React from "react";

/**
 * Context to support the roles.  Operator admins are associated with an operator ID
 *
 * Reviewed: 3/26/20
 */
export default React.createContext({
   isSuperAdmin: false,
   isOperatorAdmin: false,
   operatorId: undefined,
   logout: () => {},
});
