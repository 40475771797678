import React from "react";

/**
 * Context to handle drawer state between the app bar and the drawer.
 *
 * Reviewed: 3/26/20
 */
export default React.createContext({
   isDrawerOpen: false,
   onDrawerClose: () => {},
   onDrawerOpen: () => {},
});
