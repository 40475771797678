import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import filter from 'lodash/filter';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {parse} from 'query-string';
import React, {Fragment, useEffect, useState, useContext} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import ResponsiveMobileDrawer from '../fhg/components/ResponsiveMobileDrawer';
import Typography from '../fhg/components/Typography';
import DrawerContext from './DrawerContext';

const useStyles = makeStyles(theme => ({
   titleStyle: {
      margin: theme.spacing(3, 2, 0, 2),
   },
   newButtonStyle: {
      backgroundColor: theme.palette.primary.main,
      position: 'sticky',
      bottom: -theme.spacing(1),
   },
   buttonStyle: {
      fontWeight: 'bold',
   },
   focusVisible: {
      backgroundColor: 'transparent !important',
   },
   noScrollStyle: {
      overflow: 'hidden',
   },
   scrollStyle: {
      overflow: 'auto',
   },
}), {name: 'itemDrawerStyles'});

/**
 * Drawer to navigate to the items. Handles creating new items and editing the items.
 *
 * Reviewed: 3/26/20
 */
export default function ItemDrawer({prefixKey, uri, onGetName, items, allowCreate, useSearch, ItemEdit, ItemEditProps, backgroundColor, color}){
   const location = useLocation();
   const history = useHistory();
   const classes = useStyles();
   const {onDrawerClose} = useContext(DrawerContext);
   const [filteredItems, setFilteredItems] = useState([]);
   const [showNew, setShowNew] = useState(false);

   /**
    * Filter the items when the search parameters change in the URL.
    */
   useEffect(() => {
      /**
       * Filter the items based on the searchText.
       * @param searchText The search text to filter the items.
       * @param items The items to filter.
       * @return {Array}
       */
      const search = (searchText = '', items = []) => {
         let filteredItems;

         if (useSearch && searchText.length > 0 && items.length > 0) {
            let search = searchText.toLocaleLowerCase().trim();

            if (onGetName) {
               filteredItems = filter(items, o => onGetName(o).toLocaleLowerCase().indexOf(search) >= 0);
            } else {
               filteredItems = filter(items, o => o.name && o.name.toLocaleLowerCase().indexOf(search) >= 0);
            }
         } else {
            filteredItems = items;
         }
         return filteredItems;
      };

      const searchText = get(parse(location.search), 'searchText');
      const filteredItems = search(searchText, items);
      setFilteredItems(filteredItems);
      if (filteredItems.length === 1) {
         history.push(`${uri}/${filteredItems[0].id}${location.search}`);
      }
   }, [location.search, items, history, uri, onGetName, useSearch]);

   /**
    * Open the dialog to create/edit the items.
    */
   const openNew = () => {
      if (ItemEdit) {
         setShowNew(true);
      } else if (allowCreate) {
         onDrawerClose();
         history.push(uri, {isCreate: true});
      }
   };

   /**
    * Close the dialog onClose.
    */
   const closeNew = () => {
      setShowNew(false);
   };

   /**
    * Close the drawer when items are clicked.
    */
   const handleClick = () => {
      onDrawerClose();
   };

   return (
      <ResponsiveMobileDrawer className={`no-print`} backgroundColor={backgroundColor}>
         {(ItemEdit && showNew) && (
            <ItemEdit open={showNew} onClose={closeNew} isCreate {...ItemEditProps}/>
         )}
         <Typography variant={'h5'} className={classes.titleStyle} color={'inherit'} style={{color}}
                     id={`${prefixKey}.title`}/>
         <Grid container direction={'column'} className={classes.noScrollStyle}>
            <List className={classes.scrollStyle} dense>
               <Fragment>
                  {filteredItems.map(item => (
                     <ListItem
                        key={`itemDrawer${item.id}`}
                        button
                        component={Link}
                        to={`${uri}/${item.id}`}
                        selected={location.pathname === `${uri}/${item.id}`}
                        onClick={handleClick}
                     >
                        <ListItemText primary={onGetName ? onGetName(item) : item.name} primaryTypographyProps={{variant:'h6', style:{fontWeight: 400}}}
                                      style={{paddingLeft: 16, color}}/>
                     </ListItem>
                  ))}
               </Fragment>
               <Grid item className={classes.newButtonStyle}>
                  <Divider/>
                  <ListItem button onClick={openNew} selected={false}
                            classes={{focusVisible: classes.focusVisible}}>
                     <ListItemText disableTypography style={{paddingLeft: 16}}>
                        <Typography variant={'inherit'} className={classes.buttonStyle} style={{color}}
                                    id={`${prefixKey}.newButton.label`}/>
                     </ListItemText>
                  </ListItem>
               </Grid>
            </List>
         </Grid>
      </ResponsiveMobileDrawer>
   );
}

ItemDrawer.propTypes = {
   items: PropTypes.array,                      // List if the items in the list.
   prefixKey: PropTypes.string.isRequired,      // The prefix for title and new button label (i.e [prefixKey].newButton.label, [prefixKey].title)
   uri: PropTypes.string.isRequired,            // URI for the item links.
   ItemEdit: PropTypes.elementType,             // The dialog to edit and create new items.
   ItemEditProps: PropTypes.object,             // Props for the ItemEdit.
   useSearch: PropTypes.bool,                   // Indicates if the search parameters in the URL are used to filter items.
   allowCreate: PropTypes.bool,                 // Not used if ItemEdit is set. Indicates if the uri can be used to create items.
   backgroundColor: PropTypes.string,           // Background color of the drawer.
   color: PropTypes.string,                     // Color of the drawer text.
   onGetName: PropTypes.func,                   // callback for a custom item name.
};

ItemDrawer.defaultProps = {
   items: [],
   useSearch: true,
   allowCreate: false,
};

