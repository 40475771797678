import PropTypes from 'prop-types';
import React, {Component} from 'react';

/**
 * Form component that handles custom validation and removing prevents default for the submit event.
 *
 * Reviewed: 3/26/20
 */
class Form extends Component {
   formEl = React.createRef();
   static propTypes = {
      validate: PropTypes.bool,           // True to force validation.
      reportInvalid: PropTypes.bool,      // True to report when invalid. Can prevent reporting if needed.
      onValid: PropTypes.func,            // Callback when the form is valid.
      onSubmit: PropTypes.func.isRequired,  // Submit callback to submit the form inputs.
   };
   static defaultProps = {
      validate: false,
      reportInvalid: true,
   };
   state = {
   };

   /**
    * Rechecks validity if the validate prop changes.
    *
    * @param prevProps
    * @param prevState
    * @param snapshot
    */
   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.validate !== this.props.validate && this.props.validate) {
         if (this.validate()) {
            this.props.onValid && this.props.onValid()
         }
      }
   }

   /**
    * Validate the form data. Calls customValidity if the rest of the form is valid.
    *
    * @param isReport True if the validity should be reported.
    * @return {boolean} True if the form is valid and the custom validity is valid.
    */
   validate = (isReport = true) => {
      const { customValidity } = this.props;

      let isValid;
      let form = this.formEl.current;

      if (form) {
         isValid = form.checkValidity();
         if (isValid && customValidity) {
            isValid = customValidity();
         }
         if (!isValid && isReport) {
            return form.reportValidity();
         }
         return isValid
      }
      return true;
   };

   /**
    * Submits the form data and prevents the default for the submit event.
    * @param event The submit event.
    */
   submitHandler = (event) => {
      const { submit } = this.props;
      event && event.preventDefault();
      event && event.stopPropagation();

      submit && submit();
   };

   render() {
      const {validate, reportInvalid, ...props} = this.props;

      return (
         <form ref={this.formEl} onSubmit={this.submitHandler} {...props} className={props.className} >
            {this.props.children}
         </form>
      );
   }
}

export default Form;