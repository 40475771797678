import {Hidden} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {createMuiTheme, makeStyles, ThemeProvider, responsiveFontSizes} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import {AccountCircle} from '@material-ui/icons/';
import MenuIcon from '@material-ui/icons/Menu';
import React, {useState, useContext} from 'react';
import {useIntl} from 'react-intl';
import {Link, useRouteMatch} from 'react-router-dom';
import {LOGO_SMALL, APPBAR_HEIGHT, APPBAR_SMALL_HEIGHT, OPERATORS_PATH, USERS_PATH} from '../Constants';
import Grid from '../fhg/components/Grid';
import Typography from '../fhg/components/Typography';
import {formatMessage} from '../fhg/utils/Utils';
import AppBarContext from './AppBarContext';
import AuthContext from './AuthContext';
import DrawerContext from './DrawerContext';
import get from 'lodash/get';

const useStyles = makeStyles(theme => ({
   appBar: {
      zIndex: theme.zIndex.drawer + 1,
      flex: '0 0 auto',
      height: APPBAR_HEIGHT,
      [theme.breakpoints.down('sm')]: {
         height: APPBAR_SMALL_HEIGHT,
         paddingLeft: theme.spacing(0),
         paddingRight: theme.spacing(1),
      },
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
         paddingLeft: theme.spacing(3),
         paddingRight: theme.spacing(3),
      },
   },
   toolBarStyle: {
      [theme.breakpoints.up('sm')]: {
         height: APPBAR_HEIGHT,
         minHeight: APPBAR_HEIGHT,
      },
      [theme.breakpoints.down('sm')]: {
         height: APPBAR_SMALL_HEIGHT,
         minHeight: APPBAR_SMALL_HEIGHT,
      },
   },
   imageStyle: {
      height: 46,
      // width: 240,
      [theme.breakpoints.down('sm')]: {
         height: 30,
         marginLeft: theme.spacing(1),
      },
      [theme.breakpoints.down('xs')]: {
         height: 28,
         maxWidth: '100%',
      },
   },
   iconStyle: {
      [theme.breakpoints.down('sm')]: {
         fontSize: '1.75rem',
      },
   },
}), {name: 'webAppBarStyles'});

// Create a theme for this card that has responsive font sizes.
const materialTheme = {
   palette: {
      primary: {
         light: '#5185C2',
         main: '#1E528F',
         dark: '#002D62',
      },
      secondary: {
         light: '#41C03D',
         main: '#0E8D0A',
         dark: '#005A00',
      },
      background: {
         default: '#EFEFEF',
      }
   },
   typography: {
      button: {
         textTransform: 'none',
      }
   },
   shape: {
      borderRadius: 0,
   }
};

let theme = createMuiTheme(materialTheme);
theme = responsiveFontSizes(theme);

/**
 * The AppBar with Logout.
 *
 * Reviewed: 3/26/20
 */
export default function WebAppBar() {
   const classes = useStyles();
   const intl = useIntl();
   const {onDrawerOpen, onDrawerClose, isDrawerOpen} = useContext(DrawerContext);
   const [anchorEl, setAnchorEl] = useState();
   const {subtitleValues, titleKey, subtitleKey} = useContext(AppBarContext);
   const {isSuperAdmin} = useContext(AuthContext);
   const userMatch = useRouteMatch({
      path: USERS_PATH,
      strict: false,
      sensitive: false,
   });

   /**
    * Open the account menu.
    * @param event The menu button click event.
    */
   const handleMenu = event => {
      setAnchorEl(event.currentTarget);
   };

   /**
    * Close the account menu.
    */
   const handleClose = () => {
      setAnchorEl(null);
   };

   /**
    * Toggle the drawer with the button.
    */
   const handleMenuClick = () => {
      if (isDrawerOpen) {
         onDrawerClose();
      } else {
         onDrawerOpen();
      }
   };

   return (
      <AuthContext.Consumer>
         {({logout}) => (
            <ThemeProvider theme={theme}>
               <AppBar position='relative' color={'inherit'} className={classes.appBar}>
                  <Toolbar className={classes.toolBarStyle} disableGutters>
                     <Grid container justify={'space-between'} alignItems={'center'} direction={'row'} wrap={'nowrap'}>
                        <Grid container item direction={'row'} alignItems={'center'} fullWidth={false} spacing={2}
                              wrap={'nowrap'}>
                           {!userMatch && (
                              <Grid item resizable={false}>
                                 <Hidden mdUp implementation='css'>
                                    <IconButton variant={isDrawerOpen ? 'contained' : 'outlined'} color='primary'
                                                onClick={handleMenuClick}>
                                       <MenuIcon/>
                                    </IconButton>
                                 </Hidden>
                              </Grid>
                           )}
                           <Grid item container fullWidth={false} alignItems={'center'}>
                              <img alt='' className={classes.imageStyle} src={LOGO_SMALL}/>
                           </Grid>
                           <Grid item>
                              <Hidden smDown>
                                 <Typography id='appbar.title' variant='h5' color='inherit'
                                             className={classes.titleStyle}
                                             values={{
                                                appTitle: formatMessage(intl, titleKey),
                                                subTitle: formatMessage(intl, subtitleKey, undefined, subtitleValues)
                                             }} noWrap/>
                                 <Typography color='inherit' id={'version'} values={{version: process.env.REACT_APP_VERSION}}/>
                              </Hidden>
                              <Hidden mdUp>
                                 <Typography variant='h5' color='inherit' id={subtitleKey} values={subtitleValues}/>
                                 <Typography color='inherit' id={'version'} values={{version: process.env.REACT_APP_VERSION}}/>
                              </Hidden>
                           </Grid>
                        </Grid>
                        <Grid item container resizable={false} fullWidth={false} direction={'row'}
                              alignItems={'center'}>
                           {(isSuperAdmin && userMatch) && (
                              <Grid item>
                                 <Button component={Link} to={OPERATORS_PATH + '/' + get(userMatch, 'params.operatorId') || ''} color='primary'>
                                    <Typography noWrap id={'operators.button.label'}/>
                                 </Button>
                              </Grid>
                           )}
                           <Grid item resizable={false}>
                              <IconButton
                                 aria-owns={'menu-appbar'}
                                 aria-haspopup='true'
                                 onClick={handleMenu}
                                 color='inherit'
                              >
                                 <AccountCircle fontSize='large' color={'primary'} className={classes.iconStyle}/>
                              </IconButton>
                              <Menu
                                 id='menu-appbar'
                                 anchorEl={anchorEl}
                                 anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                 }}
                                 transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                 }}
                                 open={Boolean(anchorEl)}
                                 onClose={handleClose}
                              >
                                 <MenuItem component={Link} onClick={logout}>
                                    <Typography id='path.logout' variant='menuItem'/>
                                 </MenuItem>
                              </Menu>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Toolbar>
               </AppBar>
            </ThemeProvider>
         )}
      </AuthContext.Consumer>
   );
}
