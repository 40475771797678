import React from 'react';
import { IntlProvider } from 'react-intl';

const CURRENT_LOCALE = 'en-US';

/**
 * Normalize the locale for consistency to find the localization file.
 * @param locale The locale to normalize.
 * @return {*|string} The normalized locale.
 */
function normalizeLocale(locale) {
   let locales = locale.replace(/_/g, '-').split('-');
   let normalizedLocale = locales[ 0 ];
   if (locales.length > 1) {
      normalizedLocale += '-' + locales[ 1 ].toUpperCase();
   }

   return normalizedLocale;
}

/**
 * Get the current locale from the browser and normalize it.
 * @return {*} the normalized browser locale.
 */
export function getCurrentLocale() {
   try {
      let locale = window.navigator.languages ? window.navigator.languages[ 0 ] :
         window.navigator.language || window.navigator.userLanguage;
      return normalizeLocale(locale);
   } catch (e) {
      return CURRENT_LOCALE;
   }
}

export const defaultLocale = getCurrentLocale();

/**
 * Get the messages from the localization file.
 * @param messages The localization messages.
 * @param locale The locale to use for the message.
 * @return {*} The localization messages.
 */
const getMessages = (messages, locale) => {
   let appMessages;
   try {
      appMessages = require('./' + locale);
   } catch (e) {
      appMessages = {};
   }

   return { locale, messages: { ...appMessages, ...messages } };
};

const formats = {
   number: {
      USD: {
         style: 'currency',
         currency: 'USD'
      }
   }
};


/**
 * The HOC for handling localization by loading messages and setting up the provider.
 * @param options The options containing the locale.
 * @return {function(*): function(*): *}
 */
const withIntl = (options) => Component => {
   const locale = options.locale || defaultLocale;
   const messages = getMessages(options.messages, locale );

   function WithIntl(props) {
      return (
         <IntlProvider locale={locale} messages={messages.messages} formats={formats}>
            <Component {...props} />
         </IntlProvider>
      );
   }

   return WithIntl;
};

export default withIntl;
