import {ApolloProvider} from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import config from './Config';
import {ENDPOINT} from './Constants';
import './index.css';
import App, {userAgentApplicationState} from './pages/App';
import * as serviceWorker from './serviceWorker';

const uri = ENDPOINT;

const client = new ApolloClient({
      uri,
      request: async operation => {
         let tokenResponse;
         if (userAgentApplicationState) {
            try {
               tokenResponse = await userAgentApplicationState.acquireTokenSilent({
                  scopes: ["api://17870823-fbb2-48ef-b3ff-73d7e3d53dd7/user.read"],
               });
            } catch(error) {
               try {
                  tokenResponse = await userAgentApplicationState.acquireTokenSilent({scopes: [config.appId]});
               } catch (e) {
                  console.log(e);
               }
            }
         } else {
            console.log('no userAgentApplication');
         }

         if (tokenResponse?.idToken.rawIdToken) {
            operation.setContext({
               headers: {
                  authorization: `Bearer ${tokenResponse?.idToken.rawIdToken}`,
               }
            });
         }
      },
   })
;

// Add the format command for adding parameters to strings. For Example:
//    'This is a test: {testName}'.format({testName: 'Test Hello World'})
if (!String.prototype.format) {
// eslint-disable-next-line
   String.prototype.format = function (values) {
      return this.replace(/{(\w+)}/g, function (match, key) {
         return typeof values[key] !== 'undefined' ? values[key] : match;
      });
   };
}

ReactDOM.render(
   <ApolloProvider client={client}>
      <BrowserRouter>
         <App/>
      </BrowserRouter>
   </ApolloProvider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
