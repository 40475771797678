import React from "react";

/**
 * Context to support the appbar titles.
 *
 * Reviewed: 3/26/20
 */
export default React.createContext({
   titleKey: undefined,
   subtitleKey: undefined,
   subtitleValues: undefined,
   setTitleKey: () => {},
   setSubtitleKey: () => {},
   setSubtitleValues: () => {},
});
