import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import ErrorSnackbar from './ErrorSnackbar';

/**
 * Component to show the error messages .
 *
 * Reviewed: 3/26/20
 *
 * @param error The error to display
 * @param enableRefresh True will show the refresh button.
 * @param onClose The callback when the error snackbar is closed.
 * @param errorId The Intl Id of the message to display.
 * @param values The values for the message.
 * @param debug A debug string to help with detailed debugging.
 */
export default function DisplayError({error, enableRefresh, onClose, errorId, values, actions, debug:debugProp}) {
   const [showError, setShowError] = useState(true);
   const [value, setValue] = useState();

   /**
    * Close the error snackbar.
    */
   const handleErrorClose = () => {
      setShowError(false);
      onClose && onClose();
   };

   /**
    * When a new error comes in, update to show errors even if user has turned off show errors.
    */
   useEffect(() => {
       setShowError(!!error);
      const message = error ? get(error, 'message') || 'N/A' : undefined;
      const debug = debugProp ? debugProp : (error ? get(error, 'debug') : undefined);

       if (values) {
          setValue({...values, message, debug});
       } else {
          setValue({message, debug});
       }
   }, [error, values, debugProp]);

   if (showError) {
      return (
         <ErrorSnackbar open={true} onClose={handleErrorClose} errorId={errorId} values={value} actions={actions}
                        enableRefresh={enableRefresh}/>
      );
   }
   return null;
}

DisplayError.propTypes = {
   error: PropTypes.object,                  // The error object to display.
   enableRefresh: PropTypes.bool,            // Indicates if the Refresh action should be shown / enabled.
   onClose: PropTypes.func,                  // Callback when the snackbar is closed.
   errorId: PropTypes.string,                // Intl ID for the error message.
   values: PropTypes.object,                 // Value object for the error message.
   actions: PropTypes.array,                 // Actions to add to the ErrorSnackbar.
   debug: PropTypes.string,                 // A debug string to help with detailed debugging.
};

DisplayError.defaultProps = {
   enableRefresh: true,
   errorId: 'fetch.error',
};
