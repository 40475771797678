
// Dates displayed to the user.
export const DATE_FORMAT = 'M/D/YYYY';
export const LOGO_LARGE = '/images/Blue_180.png';
export const LOGO_SMALL= '/images/Blue_120.png';
export const LOGO_LARGE_WHITE= '/images/White_180.png';

export const drawerWidth = 300;
export const DRAWER_WIDTH = drawerWidth;

export const ENDPOINT = !process.env.REACT_APP_ENDPOINT ? '/api/graphql/' : `https://${process.env.REACT_APP_ENDPOINT}/api/graphql/`;
console.log('Version = ', process.env.REACT_APP_VERSION);

export const APPBAR_HEIGHT = 64;
export const APPBAR_SMALL_HEIGHT = 50;

export const SUPER_ADMIN_DOMAIN = 'rcp.com';

//Paths
export const RCP_LOGIN_PATH = '/rcp';
export const OPERATOR_PATH = '/operator/:id?';
export const OPERATORS_PATH = '/operator';
export const USERS_PATH = '/operator/:operatorId/user';
export const USER_PATH = '/operator/:operatorId/user/:userId?';
