import {useMutation} from '@apollo/react-hooks';
import {castArray} from 'lodash';

/**
 * Hook for useMutation that updates the cache for add and delete queries. Update mutations should automatically update
 * cache without this update.
 *
 * NOTE:
 * 1) Assumes that the result of the mutation only has a single property. (e.g. {data: {operators: {...}}})
 * 2) Updates ONLY the FIRST property in an updateQuery. The first property is assumed to be a list and adds the result
 *    property to the list. Other properties in the original query are copied to the updated cache item.
 *
 * Reviewed: 3/26/20
 *
 * @param mutation The graphql mutation.
 * @param options The options for the mutation.
 * @param updateQueries Queries that need to be updated in the cache to be in sync with the server.
 *          Example: {query: ITEM_CREATE, variables: {id: 1}}
 * @return
 */
export default function useMutationFHG(mutation, options, updateQueries) {

   const [mutationFunction, {loading, error, data}] = useMutation(
      mutation,
      {
         ...options,
         update: !!updateQueries ? (cache, {data}) => {
            //Handle one or more queries to update.
            const updateQueryList = castArray(updateQueries);
            //Works only on a single update in the results
            const resultDataItem = Object.values(data)[0];
            for (const query of updateQueryList) {
               //get the cached data for the update query.
               const queryData = cache.readQuery(query);

               //get the property of the first item in the update query results.
               const queryItemToUpdateProperty = Object.keys(queryData)[0];

               //get the value of the first item in the update query results.
               const queryItemToUpdate = Object.values(queryData)[0];

               if (queryItemToUpdate && (queryItemToUpdate.length === 0 || resultDataItem.__typename === queryItemToUpdate[0].__typename)) {
                  cache.writeQuery({
                     ...query,
                     //Add the new result data item to the list in the update query.
                     data: {...queryData, [queryItemToUpdateProperty]: queryItemToUpdate.concat([resultDataItem])},
                  });
               } else {
                  console.log('Update did not update cache for ', resultDataItem, );
               }
            }
         } : undefined,
      }
   );

   return [mutationFunction, {loading, error, data}];
}