import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
   textFieldStyle: {
      marginTop: 24,
      // marginBottom: 16,
      '& label': {
         zIndex: 0,
         marginTop: -20,
         fontWeight: 500,
         fontSize: 16,
         color: 'black !important',
         transform: 'none !important',
      },
      '& legend': {
         display: 'none !important',
      },
      '& fieldset': {
         top: 0,
      },
      '& > div': {
         marginTop: '0 !important',
      }
   }
}, {name: 'fhgTextFieldStyles'});

/**
 * The TextField with preset formats.
 *
 * Reviewed: 3/26/20
 */
export default function FHGTextField ({ className, margin, InputLabelProps, InputProps, withLabel=true, inputProps, defaultValue, value, ...textFieldProps }) {
   const classes = useStyles();

   return (
      <TextField
         key={defaultValue}
         defaultValue={defaultValue}
         value={value}
         style={{marginTop: !withLabel ? 0 : undefined}}
         {...textFieldProps}
         InputLabelProps={{
            ...InputLabelProps,
            shrink: true,
         }}
         InputProps={{
            style:{marginTop: withLabel ? 32 : undefined},
            ...InputProps,
         }}
         inputProps={{
            style:{paddingTop: !withLabel ? 5 : undefined, paddingBottom: !withLabel ? 5 : undefined},
            ...inputProps,
         }}
         className={`${classes.textFieldStyle} ${className}`}
         variant='outlined'
         size="small"
         margin={margin || 'none'}
      />
   );
}

FHGTextField.propTypes = {
   className: PropTypes.string,  // ClassName for the TextField for customizing the TextField.
   margin: PropTypes.string,     // Margin for the TextField.
   InputLabelProps: PropTypes.any,  // The InputLabel props.
   InputProps: PropTypes.any,  // The Input props.
   withLabel: PropTypes.bool,    // Indicates if the label will be used. This prevents the extra margin used for the label.
   inputProps: PropTypes.any,    // The input props.
   textFieldProps: PropTypes.any,   // All other properties are passed to the TextField.
};