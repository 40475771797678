import {useQuery} from '@apollo/react-hooks';
import {FormControl} from '@material-ui/core';
import {FormLabel} from '@material-ui/core';
import {useTheme} from '@material-ui/core';
import {Radio} from '@material-ui/core';
import {RadioGroup} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Switch from '@material-ui/core/Switch';
import gql from 'graphql-tag';
import {toNumber} from 'lodash';
import get from 'lodash/get';
import {useCallback} from 'react';
import {useRef} from 'react';
import React, {useState, useEffect} from 'react';
import DocumentTitle from 'react-document-title';
import {useIntl} from 'react-intl';
import {useParams, Link, useLocation, useHistory, Prompt} from 'react-router-dom';
import FormCard from '../../components/FormCard';
import FHGTextField from '../../components/TextField';
import {USERS_PATH, OPERATOR_PATH, OPERATORS_PATH} from '../../Constants';
import CheckboxFHG from '../../fhg/components/CheckboxFHG';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import DataLoadingAndErrors from '../../fhg/components/DataLoadingAndErrors';
import Grid from '../../fhg/components/Grid';
import FHGTypography from '../../fhg/components/Typography';
import {handleChange as handleChangeUtil} from '../../fhg/utils/Utils';
import {formatMessage} from '../../fhg/utils/Utils';
import TestLengthTable from './TestLengthTable';

const useOperatorsStyles = makeStyles(theme => ({
   contentStyle: {
      overflow: 'auto',
      height: '100%',
      flex: '1 1',
   },
   paperStyle: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      maxHeight: `calc(100% - ${theme.spacing(3)}px)`,
      [theme.breakpoints.down('sm')]: {
         margin: theme.spacing(1),
         maxHeight: `calc(100% - ${theme.spacing(2)}px)`,
      },
      maxWidth: 840,
      display: 'flex',
      overflow: 'hidden',
   },
   titleStyle: {
      marginBottom: theme.spacing(2),
   },
   titleStyleInner: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
   },
   noDataTitleStyle: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
   },
   labelStyle: {
      marginTop: 4,
      marginLeft: 4,
      '& label': {
         fontWeight: 500,
         fontSize: 16,
         color: 'black !important',
      },
   }
}), {name: 'operatorStyles'});

// Get all the operators.
export const OPERATOR_QUERY = gql`
   query getOperators {
      operators:operator_All {
         id
         name
      }
   }
`;

// Create fragment for the operator. Using the same fragment for all Operator queries, updates the cache automatically.
const OPERATOR_FRAGMENT = gql`
   fragment operatorInfo on Operator {
      id
      name
      email:adminEmail
      sendEmail
      emailRecipient
      emailType
      gpsTimeout
      minimumAllowablePressure
      defaultStabilizationPeriod
      autoDeleteDays:autoDeleteAfterDays
      isActive
      privacyPolicy
      sendFailedTests
      maximumAllowablePressureDrop
      maximumAllowablePressure
      prohibitLowBatTest
   }
`;

//Get the operator with the given operator ID.
export const OPERATOR_BY_ID_QUERY = gql`
   query getOperatorById($id: Int!)
   {
      operator:operator_ById(operatorId: $id) {
         ...operatorInfo
      }
   }
   ${OPERATOR_FRAGMENT}
`;

// Create an operator with the properties.
const OPERATOR_CREATE = gql`
   mutation OperatorCreate($name: String, $email: String, $minimumAllowablePressure: Float, $defaultStabilizationPeriod: Int, $autoDeleteDays: Int, $isActive: Boolean, $privacyPolicy: String, $sendFailedTests: Boolean, $maximumAllowablePressure: Float, $maximumAllowablePressureDrop: Float, $sendEmail: Boolean, $emailRecipient: String, $emailType: String, $gpsTimeout: Int, $prohibitLowBatTest:Boolean) {
      operator: operator_Create(operator: {name: $name, adminEmail: $email, minimumAllowablePressure: $minimumAllowablePressure, defaultStabilizationPeriod: $defaultStabilizationPeriod, autoDeleteAfterDays: $autoDeleteDays, isActive: $isActive, privacyPolicy: $privacyPolicy, sendFailedTests: $sendFailedTests, maximumAllowablePressure: $maximumAllowablePressure, maximumAllowablePressureDrop: $maximumAllowablePressureDrop, sendEmail: $sendEmail, emailRecipient: $emailRecipient, emailType: $emailType, gpsTimeout: $gpsTimeout, prohibitLowBatTest: $prohibitLowBatTest}) {
         ...operatorInfo
      }
   }
   ${OPERATOR_FRAGMENT}
`;

// Update an operator with the properties.
const OPERATOR_UPDATE = gql`
   mutation OperatorUpdate($operatorId: Int!, $name: String, $email: String, $minimumAllowablePressure: Float, $defaultStabilizationPeriod: Int, $autoDeleteDays: Int, $isActive: Boolean, $privacyPolicy: String, $sendFailedTests: Boolean, $maximumAllowablePressure: Float, $maximumAllowablePressureDrop: Float, $sendEmail: Boolean, $emailRecipient: String, $emailType: String, $gpsTimeout: Int, $prohibitLowBatTest:Boolean) {
      operator: operator_Update(operatorId: $operatorId, operator: {name: $name, adminEmail: $email, minimumAllowablePressure: $minimumAllowablePressure, defaultStabilizationPeriod: $defaultStabilizationPeriod, autoDeleteAfterDays: $autoDeleteDays, isActive: $isActive, privacyPolicy: $privacyPolicy, sendFailedTests: $sendFailedTests, maximumAllowablePressure: $maximumAllowablePressure, maximumAllowablePressureDrop: $maximumAllowablePressureDrop, sendEmail: $sendEmail, emailRecipient: $emailRecipient, emailType: $emailType, gpsTimeout: $gpsTimeout, prohibitLowBatTest: $prohibitLowBatTest}) {
         ...operatorInfo
      }
   }
   ${OPERATOR_FRAGMENT}
`;

/**
 * The Operator component to create and edit an operator.
 *
 * Reviewed: 3/26/20
 */
export default function Operator() {
   const {id} = useParams();
   const operatorId = toNumber(id);
   const location = useLocation();
   const history = useHistory();
   const intl = useIntl();
   const classes = useOperatorsStyles();
   const testLengthTableRef = useRef();

   const [defaultValues, setDefaultValues] = useState({maximumAllowablePressure: 105, maximumAllowablePressureDrop: 4});
   const {data, loading, error} = useQuery(OPERATOR_BY_ID_QUERY, {variables: {id: operatorId}, skip: !operatorId});
   const [operatorCreate, {loading: createLoading, error: createError}] = useMutationFHG(OPERATOR_CREATE, undefined,
      {query: OPERATOR_QUERY});
   const [operatorUpdate, {loading: updateLoading, error: updateError}] = useMutationFHG(OPERATOR_UPDATE);
   const [isActive, setActive] = useState(true);
   const [isChanged, setIsChanged] = useState(false);
   const [isSaving, setIsSaving] = useState(false);
   const isCreate = get(location, 'state.isCreate');
   const [editValues, setEditValues] = useState({});

   const getValue = useCallback((path, defaultValue) => {
      const editValue = get(editValues, path);
      return editValue !== undefined ? editValue : get(defaultValues, path) || defaultValue;
   }, [editValues, defaultValues]);

   /**
    * Toggle the active property. Changes are immediately saved.
    * @param event The checkbox event.
    */
   const toggleChecked = (event) => {
      setActive(event.target.checked);
      operatorUpdate({variables: {operatorId, isActive: event.target.checked}});
   };

   /**
    * Set the default values and active from the existing operator.
    */
   useEffect(() => {
      if (!!data) {
         setDefaultValues({
            ...data.operator, ...{
               maximumAllowablePressure: data.operator?.maximumAllowablePressure || 105,
               maximumAllowablePressureDrop: data.operator?.maximumAllowablePressureDrop || 4
            }
         });
         setActive(get(data, 'operator.isActive') || false);
      }
   }, [data]);

   /**
    * Handle onChange events for the inputs.
    *
    * NOTE:
    * Input components MUST have their name set to be set in the editValues.
    *
    * @param event The event that changed the input.
    */
   const handleChange = event => {
      setEditValues({
         ...editValues,
         [event.target.name]: event.target.type === 'number' ? event.target.valueAsNumber : event.target.value
      });
      setIsChanged(true);
   };

   const handleCheckboxChange = (event) => {
      const newValue = handleChangeUtil(event);

      setEditValues({
         ...editValues,
         ...newValue,
      });
      setIsChanged(true);
   };

   const handleTestLengthChange = () => {
      setIsChanged(true);
   };

   /**
    * Handle onSubmit for the form. Mutates the database object with the changes.
    */
   const handleSubmit = async () => {
      if (isChanged) {
         try {
            setIsSaving(true);
            let mutate = isCreate ? operatorCreate : operatorUpdate;
            const result = await mutate({variables: {operatorId, ...editValues, isActive}});

            await testLengthTableRef.current.submit();
            setIsChanged(false);
            setIsSaving(false);

            if (isCreate) {
               history.replace(OPERATOR_PATH.replace(':id?', result.data.operator.id));
            }
         } catch (e) {
            setIsSaving(false);
         }
      }
   };

   /**
    * Cancel the changes to the operator.
    */
   const handleCancel = () => {
      setIsChanged(false);
      setEditValues({});
      // Wait until isChanged takes affect to avoid Prompt.
      setTimeout(() => {
         history.replace(OPERATORS_PATH, {isCreate: false});
      }, 0);
   };

   //If an operator is specified...
   if (!!id) {
      // Show loading progress and errors.
      if (loading || error) {
         return <DataLoadingAndErrors error={error} isLoading={loading}/>
      } else if (!error && !(get(data, 'operator') || undefined)) {
         return (
            <FHGTypography className={classes.noDataTitleStyle} variant={'h5'} noWrap id={'operator.noOperator.title'}/>
         )
      }
      // No operator was selected and they aren't creating one.
   } else if (!id && !isCreate) {
      return (
         <FHGTypography className={classes.noDataTitleStyle} variant={'h5'} noWrap id={'operator.noOperator.label'}/>
      )
   }

   return (
      <DocumentTitle title={defaultValues.name || ''}>
         <Grid item className={classes.contentStyle}>
            <Prompt when={isChanged} message={() => formatMessage(intl, 'leavePage', 'Discard changes?')}/>
            <DataLoadingAndErrors error={error || createError || updateError}
                                  isLoading={loading || createLoading || updateLoading}/>
            <Paper square className={classes.paperStyle}>
               <FormCard onSubmit={handleSubmit} onCancel={handleCancel} disabled={isSaving}>
                  <Grid item container className={classes.titleStyle} fullWidth={true} spacing={2} resizable={false}>
                     <Grid item flex={'2000 2000'} style={{minWidth: 'min-content', maxWidth: 'fit-content'}}>
                        <FHGTypography variant={'h5'} className={classes.titleStyleInner}
                                       id={!defaultValues.name ? 'operator.new.title' : undefined}>
                           {defaultValues.name}
                        </FHGTypography>
                     </Grid>
                     <Grid item resizable={false} style={{minWidth: 'fit-content'}}>
                        <FormControlLabel
                           control={<Switch checked={isActive} onChange={toggleChecked} color={'primary'}/>}
                           label=<FHGTypography variant={'h5'} className={classes.titleStyleInner}
                           id={'operator.active.label'}/>
                        style={{marginRight: 0}}
                        />
                     </Grid>
                     <div style={{padding: 0, flex: '1 1'}}/>
                     <Grid item resizable={false}>
                        <Button component={Link} to={USERS_PATH.replace(':operatorId', id)} color='secondary'>
                           <FHGTypography noWrap id={'operator.openOperatorAdmin.title'}/>
                        </Button>
                     </Grid>
                  </Grid>
                  <Grid container direction={'row'} spacing={2} resizable overflow={'auto'}>
                     <Grid item xs={12} sm={6}>
                        <FHGTextField
                           key={defaultValues.name}
                           name={'name'}
                           label={<FHGTypography variant='inherit' id={'operator.name.label'}/>}
                           defaultValue={defaultValues.name}
                           value={editValues.name}
                           onChange={handleChange}
                           disabled={isSaving}
                           autoFocus
                           required
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <FHGTextField
                           key={defaultValues.email}
                           name={'email'}
                           type={'email'}
                           label={<FHGTypography variant='inherit' id={'operator.email.label'}/>}
                           defaultValue={defaultValues.email}
                           value={editValues.email}
                           onChange={handleChange}
                           disabled={isSaving}
                           required
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <FHGTextField
                           key={defaultValues.minimumAllowablePressure}
                           name={'minimumAllowablePressure'}
                           type={'number'}
                           inputProps={{
                              min: 0,
                              max: editValues.maximumAllowablePressure || defaultValues.maximumAllowablePressure
                           }}
                           label={<FHGTypography variant='inherit' id={'operator.minimumAllowablePressure.label'}
                                                 noWrap/>}
                           defaultValue={defaultValues.minimumAllowablePressure}
                           value={editValues.minimumAllowablePressure}
                           onChange={handleChange}
                           disabled={isSaving}
                           required
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <FHGTextField
                           key={defaultValues.maximumAllowablePressure}
                           name={'maximumAllowablePressure'}
                           type={'number'}
                           inputProps={{
                              min: editValues.minimumAllowablePressure || defaultValues.minimumAllowablePressure || 0
                           }}
                           label={<FHGTypography variant='inherit' id={'operator.maximumAllowablePressure.label'}
                                                 noWrap/>}
                           defaultValue={defaultValues.maximumAllowablePressure}
                           value={editValues.maximumAllowablePressure}
                           onChange={handleChange}
                           disabled={isSaving}
                           // required
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <FHGTextField
                           key={defaultValues.maximumAllowablePressureDrop}
                           name={'maximumAllowablePressureDrop'}
                           type={'number'}
                           inputProps={{
                              min: 0,
                              max: editValues.maximumAllowablePressure || defaultValues.maximumAllowablePressure
                           }}
                           label={<FHGTypography variant='inherit' id={'operator.maximumAllowablePressureDrop.label'}
                                                 noWrap/>}
                           defaultValue={defaultValues.maximumAllowablePressureDrop}
                           value={editValues.maximumAllowablePressureDrop}
                           onChange={handleChange}
                           disabled={isSaving}
                           // required
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <FHGTextField
                           key={defaultValues.defaultStabilizationPeriod}
                           name={'defaultStabilizationPeriod'}
                           type={'number'}
                           label={<FHGTypography variant='inherit' id={'operator.defaultStabilizationPeriod.label'}
                                                 noWrap/>}
                           defaultValue={defaultValues.defaultStabilizationPeriod}
                           value={editValues.defaultStabilizationPeriod}
                           onChange={handleChange}
                           disabled={isSaving}
                           required
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <FHGTextField
                           key={defaultValues.autoDeleteDays}
                           name={'autoDeleteDays'}
                           type={'number'}
                           label={<FHGTypography variant='inherit' id={'operator.autoDeleteDays.label'} noWrap/>}
                           defaultValue={defaultValues.autoDeleteDays}
                           value={editValues.autoDeleteDays}
                           onChange={handleChange}
                           disabled={isSaving}
                           required
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <FHGTextField
                           key={defaultValues.gpsTimeout}
                           name={'gpsTimeout'}
                           type={'number'}
                           label={<FHGTypography variant='inherit' id={'operator.gpsTimeout.label'} noWrap/>}
                           defaultValue={defaultValues.gpsTimeout}
                           value={editValues.gpsTimeout}
                           onChange={handleChange}
                           disabled={isSaving}
                           required
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} sm={6} lg={4}>
                        <CheckboxFHG
                           key={'prohibitLowBatTest'}
                           name={'prohibitLowBatTest'}
                           onChange={handleCheckboxChange}
                           color={'default'}
                           labelKey={'operator.prohibitLowBatTest.label'}
                           value={'prohibitLowBatTest'}
                           defaultChecked={defaultValues.prohibitLowBatTest}
                           checked={editValues.prohibitLowBatTest}
                           disabled={isSaving}
                           marginTop={3}
                           marginLeft={.25}
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} sm={6} lg={4}>
                        <CheckboxFHG
                           key={'sendFailedTests'}
                           name={'sendFailedTests'}
                           onChange={handleCheckboxChange}
                           color={'default'}
                           labelKey={'operator.sendFailedTests.label'}
                           value={'sendFailedTests'}
                           defaultChecked={defaultValues.sendFailedTests}
                           checked={editValues.sendFailedTests}
                           disabled={isSaving}
                           marginTop={3}
                           marginLeft={.25}
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} sm={6} lg={4}>
                        <CheckboxFHG
                           key={'sendEmail'}
                           name={'sendEmail'}
                           onChange={handleCheckboxChange}
                           color={'default'}
                           labelKey={'operator.sendEmail.label'}
                           value={'sendEmail'}
                           defaultChecked={defaultValues.sendEmail}
                           checked={editValues.sendEmail}
                           disabled={isSaving}
                           marginTop={3}
                           marginLeft={.25}
                           fullWidth
                        />
                     </Grid>
                     {getValue('sendEmail') && (
                        <>
                           <Grid item xs={12} sm={6}>
                              <FHGTextField
                                 key={defaultValues.emailRecipient}
                                 name={'emailRecipient'}
                                 label={<FHGTypography variant='inherit' id={'operator.emailRecipient.label'} noWrap/>}
                                 defaultValue={defaultValues.emailRecipient}
                                 value={editValues.emailRecipient}
                                 onChange={handleChange}
                                 disabled={isSaving}
                                 required
                                 fullWidth
                              />
                           </Grid>
                           <Grid item xs={12} sm={6}>
                              <FormControl component='fieldset' className={classes.labelStyle}>
                                 <FormLabel component='label'>Email Type</FormLabel>
                                 <RadioGroup
                                    aria-label='emailType'
                                    row
                                    name='emailType'
                                    value={getValue('emailType')}
                                    onChange={handleChange}
                                    style={{marginLeft: 8}}
                                 >
                                    <FormControlLabel
                                       value='pdf'
                                       control={<Radio color={'default'}/>}
                                       style={{color: 'rgb(133, 133, 133)'}}
                                       label='PDF'
                                    />
                                    <FormControlLabel
                                       value='csv'
                                       control={<Radio color={'default'}/>}
                                       style={{color: 'rgb(133, 133, 133)'}}
                                       label='CSV '
                                    />
                                    <FormControlLabel
                                       value='both'
                                       control={<Radio color={'default'}/>}
                                       style={{color: 'rgb(133, 133, 133)'}}
                                       label='PDF & CSV'
                                    />
                                 </RadioGroup>
                              </FormControl>
                           </Grid>
                        </>
                     )}
                     <Grid item xs={12}>
                        <FHGTextField
                           key={defaultValues.privacyPolicy}
                           name={'privacyPolicy'}
                           label={<FHGTypography variant='inherit' id={'operator.privacyPolicy.label'} noWrap/>}
                           defaultValue={defaultValues.privacyPolicy}
                           value={editValues.privacyPolicy}
                           onChange={handleChange}
                           disabled={isSaving}
                           required
                           multiline
                           rowsMax={8}
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} resizable>
                        <Divider/>
                        <TestLengthTable ref={testLengthTableRef} operatorId={operatorId}
                                         onChange={handleTestLengthChange}/>
                     </Grid>
                  </Grid>
               </FormCard>
            </Paper>
         </Grid>
      </DocumentTitle>
   );
}
