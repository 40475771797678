import {isObjectLike} from 'lodash';
import {isNil} from 'lodash';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

/**
 * Format the message for localization. The default message has the id appended in non-production versions.
 *
 * Reviewed: 3/26/20
 *
 * @param intl             // Intl for localization.
 * @param id               // Message ID from localization file.
 * @param defaultMessage   // Default message to use if id cannot be found in localization file.
 * @param values           // Values to insert in the localized message.
 * @return {string}        // Localized message.
 */
export function formatMessage(intl, id, defaultMessage, values) {
   const newDefaultMessage = process.env.NODE_ENV === 'production' ? defaultMessage : `${defaultMessage} (${id})`;

   if (id) {
      return intl ? intl.formatMessage({id, defaultMessage: newDefaultMessage}, values) : newDefaultMessage;
   } else {
      return '';
   }
}

export const editChange = (event, value, reason, isComponent = true, newValue, name) => {
   let nextValue;
   let componentName = name;

   if (newValue === undefined) {
      if (value && (reason === 'blur' || reason === 'create-option' || reason === 'select-option')) {
         nextValue = typeof value === 'string' ? value : value.id;
         componentName = name || get(event, 'target.parentElement.dataset.optionname') ||
            get(event, 'target.firstElementChild.dataset.optionname') || event.target.name;
      } else if (value && reason === 'date-picker') {
         nextValue = value;
         componentName = event.target.name;
      } else {
         if (event && event.target) {
            switch (event.target.type) {
               case 'number':
                  nextValue = event.target.valueAsNumber;
                  break;
               case 'checkbox':
                  nextValue = event.target.checked;
                  break;
               case 'date-range':
                  nextValue = event.target.date;
                  break;
               case 'react-select':
                  nextValue = event.target.value;
                  break;
               case 'react-number-format':
                  nextValue = +event.target.value;
                  break;
               default:
                  const type = get(event, 'target.dataset.type');
                  if (type === 'number') {
                     nextValue = +event.target.value;
                  } else {
                     nextValue = event.target.value;
                  }
                  break;
            }
            componentName = event.target.name;
         } else {
            console.log('event.target is null');
         }
      }
   }

   if (newValue) {
      return isComponent ? newValue : {componentName, newValue: newValue[name]};
   } else if (isComponent) {
      return {[componentName]: nextValue};
   }
   return {componentName, newValue: nextValue};
};

export const handleChange = (event, value, reason, newValue, name) => {
   return newValue ? newValue : editChange(event, value, reason, true, newValue, name);
};

/**
 * Determines if the item has a value (i.e. not undefined, not null, nor empty string).
 *
 * @param item The item to check.
 * @return {boolean} True if the item is not undefined, not null, and not the empty string.
 */
export function hasValue(item) {
   return (
      !isNil(item) &&
      item !== '' &&
      (!isArray(item) || item.length > 0) &&
      (!isObjectLike(item) || Object.keys(item).length > 0)
   );
}

export function removeOne(array, index) {
   if (index >= 0 && array && array.length) {
      let len = array.length;
      if (!len) {
         return;
      }
      len -= 1;
      while (index < len) {
         array[index] = array[index + 1];
         index++;
      }
      array.length--;
   }
   return array;
}
