import {useCallback} from 'react';
import React from 'react';
import FHGTextField from '../../components/TextField';
import ModalDialog from '../../fhg/components/dialog/ModalDialog';
import useEditData from '../../fhg/components/edit/useEditData';

/**
 * Component to edit a single test length.
 *
 * @param testLengthValue The test length to edit.
 * @param onSubmit Submit the changes to the test length.
 * @param onClose Close the edit dialog.
 * @return {JSX.Element}
 * @constructor
 */
export default function TestLengthEdit({testLengthValue, onSubmit, onClose}) {

   const [editValues, handleChange, {isChanged, getValue, defaultValues}] = useEditData(testLengthValue);

   /**
    * Submit the changes to the test length.
    * @type {(function(*): void)|*}
    */
   const handleSubmit = useCallback( (event)  => {
      if (event) {
         event.preventDefault();
         event.stopPropagation();
      }

      if (isChanged) {
         onSubmit && onSubmit({...defaultValues, ...editValues});
      }
   }, [editValues, defaultValues, isChanged, onSubmit]);

   return (
      <ModalDialog open={true} titleKey={'operator.testLengthEdit.title'} onSubmit={(event) => handleSubmit(event)} maxWidth={'sm'}
                   fullWidth onClose={onClose}>
         <FHGTextField name='minimumPipeDiameter' type='number' autoFocus label={'Minimum Pipe Diameter'}
                       style={{marginBottom: 8}}
                       onChange={handleChange} value={getValue('minimumPipeDiameter', 0)} required/>
         <FHGTextField name='minimumPipeLength' type='number' label={'Minimum Footage'} style={{marginBottom: 8}}
                       onChange={handleChange} value={getValue('minimumPipeLength', 0)} required/>
         <FHGTextField name='testLength' type='number' label={'Test Length'} onChange={handleChange}
                       value={getValue('testLength', 0)} required/>
      </ModalDialog>
   );
}
