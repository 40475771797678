import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import DocumentTitle from 'react-document-title';
import {Link} from 'react-router-dom';
import Grid from '../fhg/components/Grid';
import Typography from '../fhg/components/Typography';

const useStyles = makeStyles(theme => ({
   titleStyle: {
      width: '100%',
   },
   subtitleStyle: {
      width: '100%',
   },
   titleGridStyle: {
      marginBottom: theme.spacing(3),
   },
   actionGridStyle: {
      marginTop: theme.spacing(3),
   },
   formStyle: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
   },
}), {name: 'titleCardStyles'});

/**
 * Card with a title. Creates uniform styling for cards with titles.
 *
 * Reviewed: 3/26/20
 */
export default function TitleCard({title, titleId, subtitle, subtitleId, isForm, onSubmit, children, previous, next}) {
   const classes = useStyles();
   const FormComponent = isForm ? 'form' : 'div';

   const submitHandler = (event) => {
      event && event.preventDefault();

      onSubmit && onSubmit();
   };

   return (
      <DocumentTitle title={title}>
         <Grid name={'TitleCard-root'} container item style={{padding: 16}} fullWidth direction={'column'}
               wrap={'nowrap'}>
            <FormComponent onSubmit={isForm && submitHandler} className={classes.formStyle}>
               <Grid name={'TitleCard-title'} item className={classes.titleGridStyle} resizable={false} fullWidth>
                  <Typography id={titleId} className={classes.titleStyle} variant={'h5'} noWrap align={'center'}>
                     {title}
                  </Typography>
                  {subtitle && (
                     <Typography id={subtitleId} className={classes.subtitleStyle} variant={'subtitle1'} noWrap
                                 align={'center'}>
                        {subtitle}
                     </Typography>
                  )}
               </Grid>
               <Grid name={'TitleCard-children'} container item resizable fullWidth overflow={'auto'}
                     style={{maxHeight: 'max-content'}}>
                  {children}
               </Grid>
               <Grid name={'TitleCard-actions'} container item className={classes.actionGridStyle} resizable={false}
                     fullWidth direction={'row'} justify={'space-between'}>
                  <Button className={classes.buttonStyle} style={{visibility: !!previous ? 'visible' : 'hidden'}}
                          variant='contained' color='primary' component={Link} to={previous}>
                     <KeyboardArrowLeft/>
                     <Typography id={'previous.button'} variant={'subtitle1'}/>
                  </Button>
                  {next && (
                     <Button className={classes.buttonStyle} variant='contained' color='primary' type={'submit'}>
                        <Typography id={'next.button'} variant={'subtitle1'}/>
                        <KeyboardArrowRight/>
                     </Button>
                  )}
               </Grid>
            </FormComponent>
         </Grid>
      </DocumentTitle>
   );
}

TitleCard.propTypes = {
   title: PropTypes.string.isRequired,       //The title to display for the card.
   titleId: PropTypes.string.isRequired,     //The titleId for the title to display for the card.
   subtitle: PropTypes.string,               //The subtitle to display for the card.
   subtitleId: PropTypes.string,             //The subtitleId for the subtitle to display for the card.
   children: PropTypes.any,                  //Children for the component.
   next: PropTypes.string,                   //The path for the next card.
   previous: PropTypes.string,               //The path for the previous card.
};
