import React from 'react';
import {ENDPOINT} from './Constants';
import ApolloClient from 'apollo-boost';
import {ApolloProvider} from 'react-apollo';

const uri = process.env.NODE_ENV === 'production' ? '/api/graphql/' : ENDPOINT;

/**
 * The HOC for handling localization by loading messages and setting up the provider.
 * @return {function(*): function(*): *}
 */
const withGraphql = (getAccessToken)=> Component => {

   const client = new ApolloClient({
      uri,
      request: async operation => {
         let token;
         if (getAccessToken) {
            token = await getAccessToken();
         }
         operation.setContext({
            headers: {
               accesstoken: token || '',
            }
         });
      },
   });

   function WithGraphql(props) {
      return (
         <ApolloProvider client={client}>
            <Component {...props} />
         </ApolloProvider>
      );
   }

   return WithGraphql;
};

export default withGraphql;
