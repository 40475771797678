import {useLazyQuery} from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {StylesProvider} from '@material-ui/styles';
import gql from 'graphql-tag';
import {endsWith} from 'lodash';
import get from 'lodash/get';
import {UserAgentApplication} from 'msal';
import React, {useState, useEffect} from 'react';
import AuthContext from '../components/AuthContext';
import config from '../Config';
import {SUPER_ADMIN_DOMAIN, RCP_LOGIN_PATH} from '../Constants';
import DisplayError from '../fhg/components/DisplayError';
import {getUserDetails} from '../GraphService';
import withIntl, {defaultLocale} from '../withIntl';
import withRoot from '../withRoot';
import Main from './Main';
import SecurityCard from './SecurityCard';
import {useLocation} from 'react-router-dom';

export let userAgentApplicationState = undefined;

// Material UI default theme for the application.
/*
Main blue (light) - #1E528F
Accent blue (dark) - #002D62
Red - #CD3B49
Green - #0E8D0A
Font & Form element gray - #555555
Main Menu button background - #EFEFEF
Main Menu button border - #D8D8D8
*/
export const materialTheme = {
   palette: {
      primary: {
         light: '#5185C2',
         main: '#1E528F',
         dark: '#002D62',
      },
      secondary: {
         light: '#41C03D',
         main: '#0E8D0A',
         dark: '#005A00',
      },
      background: {
         default: '#EFEFEF',
      },
      // Custom entry
      accent: {
         main: '#CD3B49',
      }
   },
   typography: {
      button: {
         textTransform: 'none',
      }
   },
   shape: {
      borderRadius: 0,
   }
};

/*
{
  "client_id" : "17870823-fbb2-48ef-b3ff-73d7e3d53dd7",
  "authorization_user_agent" : "DEFAULT",
  "redirect_uri" : "msauth://com.rcp.gaslinetestlogger/1KHClNivQdHcTHi%2FD%2FLUWAKmH1g%3D",
  "authorities" : [
    {
      "type": "AAD",
      "audience": {
       "type": "AzureADandPersonalMicrosoftAccount",
       "tenant_id": "common"
      }
    }
  ]
}
 */

// Get the operator with a user having the given email.
const OPERATOR_ADMIN_QUERY = gql`
   query getOperatorAdmin($email: String) {
      operatorAdmins:user_AllWhere(userSearch: {email: [$email], isOperatorAdmin: [true], isActive: [true], isDeleted: [false]}) {
         id
         operatorId
      }
   }
`;

// Get all the active operator with the operator ID. This should only return one, but in an array.
const OPERATOR_ACTIVE_QUERY = gql`
   query getOperatorsActive($operatorId: Int) {
      operatorsActive:operator_AllWhere(operatorSearch:  {id: [$operatorId], isDeleted: [false], isActive: [true]}) {
         id
      }
   }
`;

/**
 * App component that handles security for the application.
 *
 * Reviewed: 3/26/20
 */
function App() {
   const [isAuthenticated, setIsAuthenticated] = useState(false);
   const [isSuperAdmin, setIsSuperAdmin] = useState(false);
   const [isOperatorAdmin, setIsOperatorAdmin] = useState(false);
   const [operatorId, setOperatorId] = useState();
   const [user, setUser] = useState({});
   const [loadOperatorAdmin, {data, error: errorOperatorAdmin}] = useLazyQuery(OPERATOR_ADMIN_QUERY);
   const [loadOperatorsActive, {data: dataActive, error: errorActive}] = useLazyQuery(OPERATOR_ACTIVE_QUERY);
   const [error, setError] = useState();
   const [errorId, setErrorId] = useState();

   const location = useLocation();

   /**
    * Setup the authentication on initialization.
    */
   useEffect(() => {
      const userAgentApplication = new UserAgentApplication({
         auth: {
            clientId: config.appId,
            redirectUri: config.redirectUri,
            scopes: config.scopes,
            authority: location.pathname === RCP_LOGIN_PATH ? `https://login.microsoftonline.com/${config.tenantId}` :
               `https://login.microsoftonline.com/common/`,
         },
         cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: true
         }
      });

      userAgentApplicationState = userAgentApplication;
      const user = userAgentApplication.getAccount();

      if (user) {
         // Enhance user object with data from Graph
         getUserProfile(userAgentApplication);
      }
// eslint-disable-next-line
   }, []);

   /**
    * Load from the server the operator admin with the email of the authenticated user.
    */
   useEffect(() => {
      if (!isAuthenticated && user && user.email) {
         loadOperatorAdmin({variables: {email: user.email}});
      }
   }, [isAuthenticated, user, loadOperatorAdmin]);

   /**
    * Load from the server the operator that is active that has the operator ID.
    */
   useEffect(() => {
      if (!!data) {
         const operatorId = get(data, 'operatorAdmins[0].operatorId');
         if (operatorId) {
            loadOperatorsActive({variables: {operatorId}});
         } else {
            setError('User is not an Operator Admin.');
            setErrorId('user.notOperatorAdmin.error');
         }
      }
   }, [data, loadOperatorsActive]);

   /**
    * If there is an active operator with the ID and there is an operator admin the the email of the authenticated user,
    * then the user is authenticated for the app.
    */
   useEffect(() => {
      if (!!dataActive) {
         const isOperatorAdmin = get(data, 'operatorAdmins.length') > 0;
         const isOperatorActive = get(dataActive, 'operatorsActive.length') > 0;
         if (isOperatorActive) {
            setIsOperatorAdmin(isOperatorAdmin);
            setIsAuthenticated(isOperatorAdmin);
            if (isOperatorAdmin) {
               setOperatorId(get(data, 'operatorAdmins[0].operatorId'));
            } else {
               setError('User is not an Operator Admin.');
               setErrorId('user.notOperatorAdmin.error');
            }
         } else {
            setError('Operator is not active.');
            setErrorId('operator.notOperatorActive.error');
         }
      }
   }, [dataActive, data]);

   /**
    * Login to the application through Microsoft accounts.
    *
    * @return {Promise<void>}
    */
   const login = async () => {
      try {
         await userAgentApplicationState.loginPopup({
            auth: {
               clientId: config.appId,
               redirectUri: config.redirectUri,
                  scopes: ["https://graph.microsoft.com/User.ReadWrite", "api://17870823-fbb2-48ef-b3ff-73d7e3d53dd7/user.read"]
            },
            cache: {
               cacheLocation: 'sessionStorage',
               storeAuthStateInCookie: true
            }
         });
         getUserProfile(userAgentApplicationState);
      } catch (err) {

         let error;

         if (typeof (err) === 'string') {
            let errParts = err.split('|');
            error = errParts.length > 1 ?
               {message: errParts[1], debug: errParts[0]} :
               {message: err};
         } else {
            error = {
               message: err.message,
               debug: JSON.stringify(err)
            };
         }

         setError(error);
         setIsAuthenticated(false);
         setUser({});
      }
   };

   /**
    * Logout of the application.
    */
   const logout = () => {
      userAgentApplicationState.logout();
   };

   /**
    * Get the basic user account information.
    *
    * @param userAgentApplication The user agent.
    * @return {{displayName: string, email: *}}
    */
   const getUserFromAccount = (userAgentApplication) => {
      let email, displayName;

      let user = userAgentApplication.getAccount();
      if (!!user) {
         email = (user.mail || user.userPrincipalName || user.userName) || '';
         displayName = (user.displayName || user.name) || 'N/A';
      } else {
         email = '';
         displayName = 'N/A';
      }
      return {email, displayName};
   };

   /**
    * Get the user profile information from the Microsoft account.
    *
    * @return {Promise<void>}
    */
   const getUserProfile = async (userAgentApplication) => {
      let user;
      try {
         // Get the access token silently
         // If the cache contains a non-expired token, this function
         // will just return the cached token. Otherwise, it will
         // make a request to the Azure OAuth endpoint to get a token

         let accessToken;
         if (config.scopes) {
            accessToken = await userAgentApplication.acquireTokenSilent({
               scopes: config.scopes,
            });
         }

         if (accessToken) {
            // Get the user's profile from Graph
            const userDetails = await getUserDetails(accessToken);
            user = {
               email: userDetails.mail || userDetails.userPrincipalName,
               displayName: userDetails.displayName,
            }
         }
      } catch (err) {
         let error;
         if (typeof (err) === 'string') {
            let errParts = err.split('|');
            error = errParts.length > 1 ?
               {message: errParts[1], debug: errParts[0]} :
               {message: err};
         } else {
            error = {
               message: err.message,
               debug: JSON.stringify(err)
            };
         }
         console.log('Error getting user information', error);
         setError(error);
      } finally {
         //Getting the specific user information may fail, but if the user is authenticated, use the basic profile info.
         if (!user) {
            user = getUserFromAccount(userAgentApplication);
         }
         // A valid super admin is someone with the domain specified.
         const isSuperAdmin = endsWith(user.email, SUPER_ADMIN_DOMAIN);
         setIsSuperAdmin(isSuperAdmin);
         setIsAuthenticated(isSuperAdmin);
         setUser(user);
      }
   };

   return (
      <AuthContext.Provider value={{isSuperAdmin, isOperatorAdmin, operatorId, logout}}>
         <StylesProvider>
            <CssBaseline/>
            <DisplayError
               error={error || errorOperatorAdmin || errorActive}
               errorId={errorId}
               actions={errorId === 'user.notOperatorAdmin.error' ? [<Button color='inherit' onClick={logout}>Log Out</Button>] : undefined}
               values={{email: errorId === 'user.notOperatorAdmin.error' && (user.email)}}
               enableRefresh={errorId !== 'user.notOperatorAdmin.error'}
            />
            {(isAuthenticated) ? (
               <Main/>
            ) : (
               <SecurityCard title={'Sign In'} actionLabelKey={'signIn.label'} onAction={login}
                             disabled={!!error}/>
            )}
         </StylesProvider>
      </AuthContext.Provider>
   );
}

/**
 * Initialize the localization messages for the application.
 */
let messages;
let test = defaultLocale || navigator.language;
try {
   messages = require(`../messages/${test}`);
} catch (e) {
   console.log(e);
   // If the default locale messages fail, fall back to US English messages.
   messages = require('../messages/en-US');
}
//Setup the localization HOC and the Material-UI HOC with a theme for the application.
export default withIntl({messages})(withRoot(materialTheme, App));
