import Drawer from '@material-ui/core/Drawer/Drawer';
import Hidden from '@material-ui/core/Hidden';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import DrawerContext from '../../components/DrawerContext';
import {DRAWER_WIDTH, APPBAR_HEIGHT, APPBAR_SMALL_HEIGHT} from '../../Constants';

const useStyles = makeStyles(theme => ({
   drawer: {
      height: '100%',
      '@media print': {
         display: 'none',
      },
      [theme.breakpoints.up('md')]: {
         width: DRAWER_WIDTH,
         flexShrink: 0,
      },
   },
   drawerPaper: {
      width: DRAWER_WIDTH,
      maxWidth: '100%',
      [theme.breakpoints.up('sm')]: {
         marginTop: APPBAR_HEIGHT,
         height: `calc(100% - ${APPBAR_HEIGHT}px)`,
      },
      [theme.breakpoints.down('sm')]: {
         marginTop: APPBAR_SMALL_HEIGHT,
         height: `calc(100% - ${APPBAR_SMALL_HEIGHT}px)`,
      },
   },
}), {name: 'responsiveMobileDrawerStyles'});

/**
 * Responsive Drawer Component which changes between a permanent drawer above the breakpoint and is temporary at or
 * below the breakpoint. The close button floats above the children and stays at in the top right of the component. The
 * children should allow space in the upper right corner for the close button.
 *
 * Reviewed: 3/26/20
 *
 * @param children The children of the component.
 * @param width Override the width of the drawer.
 * @param backgroundColor The background color of the drawer.
 * @return {*}
 * @constructor
 */
export default function ResponsiveMobileDrawer({children, width, backgroundColor}) {
   const classes = useStyles();
   const {onDrawerClose, onDrawerOpen, isDrawerOpen} = useContext(DrawerContext);

   return (
      <nav className={classes.drawer} aria-label='Operator list'>
         {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
         <Hidden mdUp implementation='css'>
            <SwipeableDrawer
               variant='temporary'
               anchor='left'
               open={isDrawerOpen}
               onClose={onDrawerClose}
               onOpen={onDrawerOpen}
               PaperProps={{style: {width, backgroundColor}}}
               classes={{
                  paper: classes.drawerPaper,
               }}
               ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
               }}
            >
               {children}
            </SwipeableDrawer>
         </Hidden>
         <Hidden smDown implementation='css'>
            <Drawer
               anchor='left'
               PaperProps={{style: {width, backgroundColor}}}
               classes={{
                  paper: classes.drawerPaper,
               }}
               variant='permanent'
               open
            >
               {children}
            </Drawer>
         </Hidden>
      </nav>
   );
}

ResponsiveMobileDrawer.propTypes = {
   children: PropTypes.any.isRequired,       // Children in the drawer.
   width: PropTypes.string,                  // The width of the drawer.
   backgroundColor: PropTypes.string,        // Background color of the drawer.
};
